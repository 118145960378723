<template>
  <section class="homepage__multitasks md-layout-item md-small-size-100">
    <HomepageSmallCard
      v-if="!isEmployee"
      :actions="settingActions"
      :title="$t('menu.setting')"
    >
      <slot>
        <MeepIconSettings class="app-icon" />
      </slot>
    </HomepageSmallCard>
    <HomepageSmallCard
      v-if="isAdmin"
      :actions="communicateActions"
      :title="$t('menu.chat')"
    >
      <slot>
        <MeepIconChat class="app-icon" />
      </slot>
    </HomepageSmallCard>
    <HomepageSmallCard
      v-if="canSeeCloud"
      class="cloud-jepa-custom"
      :actions="cloudActions"
      :title="$t('menu.cloud')"
      :isSecondCard="isJEPA && isClient"
      :title2="$t('menu.deposits.title')"
      :actions2="depositActions"
    >
      <slot>
        <MeepIconCloud class="app-icon-stroke" />
      </slot>
    </HomepageSmallCard>
    <HomepageSmallCard
      v-if="isEmployee"
      :actions="simulateursAction"
      :title="$t('menu.simulateurs')"
    >
      <slot>
        <MeepIconSimulateurs class="app-icon" />
      </slot>
    </HomepageSmallCard>
    <HomepageSmallCard
      v-if="!isJEPA & !isEmployee"
      :actions="taskActions"
      :title="$t('menu.task')"
    >
      <slot>
        <MeepIconTask class="app-icon-stroke" />
      </slot>
    </HomepageSmallCard>
    <SelectModal
      v-if="isShowModal"
      :items="items"
      property="file_name"
      value="id"
      @close="changeShowModal"
      @confirm="changeRoute"
    >
      <MeepIconCloud class="app-icon-stroke" />
    </SelectModal>
  </section>
</template>

<script>
import HomepageSmallCard from "./HomepageSmallCard.vue";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";
import MeepIconCloud from "@/components/icons/MeepIconCloud.vue";
import MeepIconTask from "@/components/icons/MeepIconTask.vue";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import SelectModal from "@/components/modal/SelectModal.vue";
import filesModel from "../../model/files";
import { mapGetters } from "vuex";
import { JEPA_FOLDER } from "@/dto/jepa-folder.enum";

export default {
  name: "HomepageMultitasks",
  components: {
    HomepageSmallCard,
    MeepIconSettings,
    MeepIconChat,
    MeepIconCloud,
    MeepIconTask,
    SelectModal,
    MeepIconSimulateurs,
  },
  data() {
    return {
      taskActions: [
        {
          title: this.$t("homepage.tasks-action.create"),
          link: "tasks/create",
        },
        {
          title: this.$t("homepage.tasks-action.companies"),
          link: "tasks/tab-all",
        },
      ],
      isShowModal: false,
      items: [],
      selectedId: "",
      files: [],
    };
  },
  computed: {
    ...mapGetters([
      "isLCM",
      "isJEP",
      "isCE",
      "isJEPA",
      "isMEEP",
      "isAdmin",
      "isClient",
      "isEmployee",
    ]),
    simulateursAction() {
      return [
        {
          title: this.$t("simulateurs.credit.title"),
          link: "/dashboard/simulateurs/credit",
        },
        {
          title: this.$t("simulateurs.frais.title"),
          link: "/dashboard/simulateurs/frais",
        },
        {
          title: this.$t("simulateurs.impot-sur.title"),
          link: "/dashboard/simulateurs/impot-sur",
        },
        {
          title: this.$t("simulateurs.impot-societe.title"),
          link: "/dashboard/simulateurs/impot-societe",
        },
        {
          title: this.$t("simulateurs.tva.title"),
          link: "/dashboard/simulateurs/tva",
        },
        {
          title: this.$t("simulateurs.salary.title"),
          link: "/dashboard/simulateurs/salary",
        },
        {
          title: this.$t("simulateurs.marge.title"),
          link: "/dashboard/simulateurs/marge",
        },
        {
          title: this.$t("simulateurs.taxe.title"),
          link: "/dashboard/simulateurs/taxe",
        },
      ];
    },
    cloudActions() {
      if (this.isEmployee) {
        return [
          {
            title: this.$t("homepage.cloud-action.upload"),
            click: this.changeShowModal,
          },
        ];
      } else {
        return [
          {
            title: this.$t("homepage.cloud-action.upload"),
            click: this.changeShowModal,
          },
          {
            title: this.$t("homepage.cloud-action.companies"),
            link: "cloud",
          },
        ];
      }
    },

    settingActions() {
      if (this.isJEPA) {
        if (this.isAdmin) {
          return [
            {
              title: this.$t("homepage.settings-action.users"),
              link: "setting/users/create",
            },
            {
              title: this.$t("homepage.settings-action.cloud"),
              link: "setting/fiscal-year",
            },
          ];
        } else {
          return [
            {
              title: this.$t("homepage.settings-action.information"),
              link: "tax/",
              class: "client-jepa",
            },
          ];
        }
      } else if (this.isClient) {
        return [
          {
            title: this.$t("homepage.settings-action.users"),
            link: "setting/users/create",
          },
        ];
      } else {
        return [
          {
            title: this.$t("homepage.settings-action.companies"),
            link: "setting/companies/create",
          },
          {
            title: this.$t("homepage.settings-action.users"),
            link: "setting/users/create",
          },
          {
            title: this.$t("homepage.settings-action.groupes"),
            link: "setting/groupes/create",
          },
        ];
      }
    },

    communicateActions() {
      return [
        {
          title: this.$t("homepage.communicate-action.new"),
          link: "communicate/organization-news/create",
        },
        {
          title: this.$t("homepage.communicate-action.notification"),
          link: "communicate/pushs/create",
        },
      ];
    },

    hasCommunicate() {
      return !this.isClient;
    },
    canSeeCloud() {
      if (this.isEmployee) {
        return this.items.length;
      }
      return true;
    },
    depositActions() {
      return [
        {
          title: this.$t("menu.deposits.taxes"),
          link: `/dashboard/cloud/${this.getIdFolder(JEPA_FOLDER.TAX)}`,
        },
        {
          title: this.$t("menu.deposits.sci"),
          link: `/dashboard/cloud/${this.getIdFolder(JEPA_FOLDER.SCI)}`,
        },
        {
          title: this.$t("menu.deposits.lmnp"),
          link: `/dashboard/cloud/${this.getIdFolder(JEPA_FOLDER.LMNP)}`,
        },
      ];
    },
  },

  async mounted() {
    this.items = await filesModel.getRoot();
    this.files = await filesModel.getTaxFolders();
  },
  methods: {
    changeShowModal() {
      this.isShowModal = !this.isShowModal;
    },

    changeRoute(id) {
      this.changeShowModal();
      this.$router.push(`cloud/${id}`);
    },
    getIdFolder(name) {
      const folder = this.files.find(file => file.file_name === name);
      return folder ? folder.id : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variable.scss";

.homepage__multitasks {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: toRem(10);
  padding: 0;
  padding-right: 0;
  align-content: flex-start;
  @include for-sm {
    grid-template-columns: repeat(2, 1fr);
  }
  @include for-lg {
    gap: toRem(22);
  }
}
</style>
